(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Insurances
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Insurances', Insurances);

  function Insurances($resource, EnvironmentConfig) {
    // return $resource(EnvironmentConfig.API + '/programmes/:idP/sports/:idS/categories/:idC/athleteinsurance', {
    return $resource(EnvironmentConfig.API + '/athleteinsurance?programme=:idP&sport=:idS&category=:idC', {
      idP: '@idP',
      idS: '@idS',
      idC: '@idC'
    });
  }
}());
